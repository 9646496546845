/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// Variables
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

$bodyfont:'Poppins', sans-serif;
$headingfont:'Poppins', sans-serif;

/*Theme Colors*/

$colorbase: #007db7;
$colorproveedor: #837450;

/*$topbar: #1E88E5 !important;*/
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt:#f2f6f8;
$bodycolor: #f8f7fb;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4 ;
$buttons-color: #474747;

$light-text: #a6b7bf;
$themecolor: #837450;
$themecolor-alt: #474747;
$themecolor-dark: #028ee1;

/*bootstrap Color*/
/*$danger: #fc4b6c;*/
$danger: #f36e6e;
$success: #26c6da;
$warning: #ffb22b;
$primary: #474747;
/*$info: #1e88e5;*/
$info: #474747;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdeb;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #cfecfe;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4; 



$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
/*$primary-dark: #6352ce;*/
$primary-dark: #282828;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent:rgba(0, 0, 0, 0.05);

$info-shadow: 0 2px 2px 0 rgba(71,71,71,.14), 0 3px 1px -2px rgba(71,71,71,.2), 0 1px 5px 0 rgba(71,71,71,.12);
$info-shadow-hover:0 14px 26px -12px rgba(71,71,71,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(17,17,17,.2);

$warning-shadow:0 2px 2px 0 rgba(248,194,0,.14), 0 3px 1px -2px rgba(248,194,0,.2), 0 1px 5px 0 rgba(248,194,0,.12);
$warning-shadow-hover:0 14px 26px -12px rgba(248,194,0,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(248,194,0,.2);

$danger-shadow:0 2px 2px 0 rgba(239,83,80,.14), 0 3px 1px -2px rgba(239,83,80,.2), 0 1px 5px 0 rgba(239,83,80,.12);
$danger-shadow-hover:0 14px 26px -12px rgba(239,83,80,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(239,83,80,.2);

$success-shadow:0 2px 2px 0 rgba(40,190,189,.14), 0 3px 1px -2px rgba(40,190,189,.2), 0 1px 5px 0 rgba(40,190,189,.12);
$success-shadow-hover:0 14px 26px -12px rgba(40,190,189,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(40,190,189,.2);
    
$primary-shadow:0 2px 2px 0 rgba(116,96,238,.14), 0 3px 1px -2px rgba(116,96,238,.2), 0 1px 5px 0 rgba(116,96,238,.12);
$primary-shadow-hover:0 14px 26px -12px rgba(116,96,238,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(116,96,238,.2);

$default-shadow:0 2px 2px 0 rgba(169,169,169,.14), 0 3px 1px -2px rgba(169,169,169,.2), 0 1px 5px 0 rgba(169,169,169,.12);
$default-shadow-hover:0 14px 26px -12px rgba(169,169,169,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(169,169,169,.2);

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border:#f3f1f1;
$card-brd:#d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;
/*Preloader*/
.preloader{
    width: 100%;
    height: 100%;
    top:0px;
    position: fixed;
    z-index: 99999;
    background: #fff;
    .cssload-speeding-wheel{
        position: absolute;
        top: calc(50% - 3.5px);
        left: calc(50% - 3.5px);
    }
}

/* formularios y tablas*/
.form-control,
.table{
    font-size:0.9rem !important;
}

.form-group{
    margin-bottom: 15px !important;
}


/* Estilos generales */
.u-overflow-x{
    overflow-x: auto;
}

.u-block{
    display:block;
    width:100%;
}

.u-bold{
    font-weight: bold;
}

.u-pb-10{
    padding-bottom:10px;
}

.u-iblock{
  display:inline-block;
}

.u-fw-normal{
    font-weight: 400;
}

.o-divider{
    display:block;
    border-bottom:1px solid #e9e9e9;
    margin: 0 0 10px 0;
}

.u-mh-200{
    min-height: 200px;
}

.u-download-item{
  @media (min-width: 768px){
    text-align: right;
  }
}

//Estados
.u-status{
    font-size:14px;
    font-weight:500;
}

.u-status{
    &--default{color:$bodytext;} //Pendiente de validación, sin enviar
    &--info{color:$colorbase;}    //enviada
    &--success{color:#00c9b2;} //validada, pagada
    &--danger{color:$danger;} //rechazada, no validada
}

//Anchura de la ventana modal
.modal-lg{
  @media (min-width: 768px){
    max-width: 700px;
  }
  @media (min-width: 990px){
    max-width: 890px;
  }
}


//Notificaciones listado
.cp-notification-page__row{
/*  border-bottom:1px solid $card-brd;*/
  padding: 10px 0;
  &:nth-child(2n){
    background:#f2f4f8;
  }
}

.cp-notification-page__icon{
  border-radius: 50%;
  margin: 5px 5px 5px 15px;
  width: 40px;
  height: 40px;
  @media (max-width: 767px){
    margin-left:20px;
  }
}

.cp-notification-page__icon-inner{
  line-height: 1;
}

.cp-notification-page__text{
  @media (max-width: 767px){
    margin-left:10px;
    margin-right:10px;
  }
}


//Lupa en input
.u-input-searching{
  padding-left: 2.25rem;
  background-repeat: no-repeat;
  background-position: center left .5625rem;
  -webkit-background-size: 1.125rem 1.125rem;
  background-size: 1.125rem 1.125rem;
  background-image: url(/material-assets/images/icon/search.svg);
}

//Miniaturas upload pdf
.dropzone .dz-preview{
  z-index:1;
}

.dropzone .dz-preview .dz-image{
  border:1px solid #c7c7c7;
}

.dropzone .dz-preview .dz-details{
  opacity:1;
}